import React from 'react';
import Layout from 'components/Layout';
import styled from 'styled-components';
import { BaseTransparentP, HeaderMedium, BaseP } from 'components/typography';
import { useIntl, Link, FormattedMessage } from "gatsby-plugin-intl";
import {
  HeaderContainer,
  RelativeWrapperWithImage,
  MainSection,
  RelativeWrapper,
} from 'components/pages/Integrations/styled';
import { WidthWrapper } from 'components/wrappers';
import TabNavigator from 'components/TabNavigator';
import { apiDocsLinks, apiDocsLinksChinese, apiDocsLinksKorean, apiDocsLinksJapanese } from 'components/Layout/navigation';
import Helmet from 'react-helmet';

const TabNav = styled.div`

  .en .zh {
    display:none;
  }

  .en .kr {
    display:none;
  }

  .en .ja {
    display:none;
  }

  .zh .en {
    display:none;
  }

  .zh .kr {
    display:none;
  }

  .zh .ja {
    display:none;
  }

  .kr .en {
    display:none;
  }

  .kr .zh {
    display:none;
  }

  .kr .ja {
    display:none;
  }

  .ja .en {
    display:none;
  }

  .ja .zh {
    display:none;
  }

  .ja .kr {
    display:none;
  }

`;

const Wrapper = styled(WidthWrapper).attrs({ size: 980 })`
  ${HeaderMedium} {
    padding-top: 100px;
    text-align: center;
  }
  .card {
   margin-top: 10px;
  }
  .header-title {
    margin-top: 40px;
    margin-bottom:10px;
  }
  h1.header-title {
    font-size:16px;
  }
  .details, li {
    margin-top: 10px;
  }
  .table {
    width: 100%;
    margin-bottom: 1rem;
    color: #212529;
  }
  .table th, .table td {
    padding: 0.5rem 1rem;
    font-size: 16px;
    vertical-align: top;
    border-top: 1px solid #e9ecef;
  }
  .api-field-name {
    font-weight: 700;
  }
  .container-code {
    background: #f6f6f6;
    border-radius: 5px;
    padding: 10px 15px;
  }
  .get-api {
    margin-left: 15px;
  }
  pre {
    font-size: 87.5%;
    color: #212529;
    margin-top: 0;
    margin-bottom: 1rem;
    overflow: auto;
    height: auto;
    font-family: CircularStd;
  }
  .bottom-bracket {
    margin-left: 30px;
  }
  .second-l-bracker {
      margin-left: 60px;
  }
  .list-order{
    margin-left: 80px;
  }
  @media 
  only screen and (max-width: 760px),
  (min-width: 472px) and (max-width: 1024px)  {

  table, thead, tbody, th, td, tr { 
  display: block; 
  }
`;
const Breadcrumbs = styled.p`
    padding: 25px 0px 25px 50px;
<<<<<<< HEAD
<<<<<<< HEAD
    width:100%;
    border-top: 1px solid #e3ebe9;
    border-bottom: 1px solid #e3ebe9;
=======
    width:100%
>>>>>>> bf7b6d65e (27-09-2022)
=======
    width:100%;
    border-top: 1px solid #e3ebe9;
    border-bottom: 1px solid #e3ebe9;
>>>>>>> 7184fbe7c (28-09-22-technical-changes)
`;

const OrdersAPI = () => {
  const intl = useIntl()
  return (
    <Layout>
      <Helmet>
        {/* <title>Orders API | Shipkoo</title> */}
        <title>{intl.formatMessage({ id: "ecommerce-meta-title-orders-api" })}</title>
        <link rel="alternate" href={intl.formatMessage({ id: "orders-api-link" })} hreflang={intl.formatMessage({ id: "language" })} />
        <link rel="alternate" href={intl.formatMessage({ id: "orders-api-link-alt" })} hreflang={intl.formatMessage({ id: "language-alt" })} />
        <link rel="alternate" href={intl.formatMessage({ id: "orders-api-link-alt2" })} hreflang={intl.formatMessage({ id: "language-alt2" })} />
        <link rel="alternate" href={intl.formatMessage({ id: "orders-api-link-alt3" })} hreflang={intl.formatMessage({ id: "language-alt3" })} />
        <meta name="description" content="Shipkoo's orders API enables you to process and manage your orders. Get the order API now." />
        <meta property="og:title" content={intl.formatMessage({ id: "ecommerce-meta-title-orders-api" })} />
        <meta property="og:description" content="Shipkoo's orders API enables you to process and manage your orders. Get the order API now." />
        <meta name="twitter:title" content={intl.formatMessage({ id: "ecommerce-meta-title-orders-api" })} />
        <meta name="twitter:description" content="Shipkoo's orders API enables you to process and manage your orders. Get the order API now." />
      </Helmet>
      <TabNav>
        <div className={intl.formatMessage({ id: "language" })}>
          <div className="en">
            <TabNavigator className="en" tabs={apiDocsLinks} activeTab={apiDocsLinks[2].value} />
          </div>
          <div className="zh">
            <TabNavigator className="zh" tabs={apiDocsLinksChinese} activeTab={apiDocsLinksChinese[2].value} />
          </div>
          <div className="kr">
            <TabNavigator className="kr" tabs={apiDocsLinksKorean} activeTab={apiDocsLinksKorean[2].value} />
          </div>
          <div className="ja">
            <TabNavigator className="ja" tabs={apiDocsLinksJapanese} activeTab={apiDocsLinksJapanese[2].value} />
          </div>
        </div>
      </TabNav>
      <Breadcrumbs><Link to="/">Home</Link> / <Link to="/ecommerce-store-integration/">{intl.formatMessage({ id: "menu-platform-integrations" })}</Link> / {intl.formatMessage({ id: "order-api-title" })}</Breadcrumbs>
      <RelativeWrapperWithImage>
        <WidthWrapper whiteMobile>
          <Wrapper>
            <div className="card">
              <div className="card-body">
                <h1 className="header-title">{intl.formatMessage({ id: "order-api-title" })}</h1>
                  <p className="details">{intl.formatMessage({ id: "order-api-desc" })}</p>
                <hr />
                <ul>
                  <li>
                    <p>GET /api/order</p>
                    <p><small>Retrieves a list of all the orders</small></p>
                  </li>
                  <li>
                    <p>GET /api/order/{"{id}"}</p>
                    <p><small>Retrieves a specific order</small></p>
                  </li>
                  <li>
                    <p>POST /api/order</p>
                    <p><small>Creates a new order</small></p>
                  </li>
                </ul>
                <hr />
                <table className="table">
                    <tbody>
                        <tr>
                            <td className="api-field-name">id</td>
                            <td>
                                <p>The ID or unique identifier of the order</p>
                                <pre className="container-code">"id": 1</pre>
                            </td>
                        </tr>
                        <tr>
                            <td className="api-field-name">po_number</td>
                            <td>
                                <p>Purchase order number</p>
                                <pre className="container-code">"po_number": "PO0000000001"</pre>
                            </td>
                        </tr>
                        <tr>
                            <td className="api-field-name">tracking_number</td>
                            <td>
                                <p>Tracking number</p>
                                <pre className="container-code">"tracking_number": "547564789798789"</pre>
                            </td>
                        </tr>
                        <tr>
                            <td className="api-field-name">status</td>
                            <td>
                                <p>---</p>
                                <pre className="container-code">"status": "---"</pre>
                            </td>
                        </tr>
                        <tr>
                            <td className="api-field-name">name</td>
                            <td>
                                <p>Customer's name</p>
                                <pre className="container-code">"name": "John Doe"</pre>
                            </td>
                        </tr>
                        <tr>
                            <td className="api-field-name">email</td>
                            <td>
                                <p>Customer's email address</p>
                                <pre className="container-code">"email": "johndoe@email.com"</pre>
                            </td>
                        </tr>
                        <tr>
                            <td className="api-field-name">phone</td>
                            <td>
                                <p>Customer's phone number</p>
                                <pre className="container-code">"phone": "(999) 999-9999"</pre>
                            </td>
                        </tr>
                        <tr>
                            <td className="api-field-name">address_line_1</td>
                            <td>
                                <p>Customer's address (line 1)</p>
                                <pre className="container-code">"address_line_1": "1F 111, Block 10"</pre>
                            </td>
                        </tr>
                        <tr>
                            <td className="api-field-name">address_line_2</td>
                            <td>
                                <p>Customer's address (line 2)</p>
                                <pre className="container-code">"address_line_2": "LongJun Industrial Zone, Dalang"</pre>
                            </td>
                        </tr>
                        <tr>
                            <td className="api-field-name">zip</td>
                            <td>
                                <p>Customer's Zip/Postal Code</p>
                                <pre className="container-code">"zip": "511700"</pre>
                            </td>
                        </tr>
                        <tr>
                            <td className="api-field-name">city</td>
                            <td>
                                <p>Customer's city</p>
                                <pre className="container-code">"city": "Shenzhen"</pre>
                            </td>
                        </tr>
                        <tr>
                            <td className="api-field-name">province</td>
                            <td>
                                <p>Customer's province</p>
                                <pre className="container-code">"province": "Guangdong"</pre>
                            </td>
                        </tr>
                        <tr>
                            <td className="api-field-name">country</td>
                            <td>
                                <p>Customer's country</p>
                                <pre className="container-code">"country": "China"</pre>
                            </td>
                        </tr>
                        <tr>
                            <td className="api-field-name">remarks</td>
                            <td>
                                <p>Additional remarks</p>
                                <pre className="container-code">"remarks": "Deliver on time"</pre>
                            </td>
                        </tr>
                        <tr>
                            <td className="api-field-name">order_date</td>
                            <td>
                                <p>Additional remarks</p>
                                <pre className="container-code">"order_date": "01/01/2020"</pre>
                            </td>
                        </tr>
                        <tr>
                            <td className="api-field-name">processed_date</td>
                            <td>
                                <p>Additional remarks</p>
                                <pre className="container-code">"processed_date": "01/02/2020"</pre>
                            </td>
                        </tr>
                        <tr>
                            <td className="api-field-name">closed_date</td>
                            <td>
                                <p>Additional remarks</p>
                                <pre className="container-code">"closed_date": "01/03/2020"</pre>
                            </td>
                        </tr>
                        <tr>
                            <td className="api-field-name">cancelled_date</td>
                            <td>
                                <p>Additional remarks</p>
                                <pre className="container-code">"cancelled_date": "01/04/2020"</pre>
                            </td>
                        </tr>
                        <tr>
                            <td className="api-field-name">cancel_reason</td>
                            <td>
                                <p>Additional remarks</p>
                                <pre className="container-code">"cancel_reason": "---"</pre>
                            </td>
                        </tr>
                        <tr>
                            <td className="api-field-name">subtotal_price</td>
                            <td>
                                <p>Additional remarks</p>
                                <pre className="container-code">"subtotal_price": "500"</pre>
                            </td>
                        </tr>
                        <tr>
                            <td className="api-field-name">shipping_cost</td>
                            <td>
                                <p>Additional remarks</p>
                                <pre className="container-code">"shipping_cost": "100"</pre>
                            </td>
                        </tr>
                        <tr>
                            <td className="api-field-name">total_tax</td>
                            <td>
                                <p>Additional remarks</p>
                                <pre className="container-code">"total_tax": "15"</pre>
                            </td>
                        </tr>
                        <tr>
                            <td className="api-field-name">total_discounts</td>
                            <td>
                                <p>Additional remarks</p>
                                <pre className="container-code">"total_discounts": "0"</pre>
                            </td>
                        </tr>
                        <tr>
                            <td className="api-field-name">total_price</td>
                            <td>
                                <p>Additional remarks</p>
                                <pre className="container-code">"total_price": "615"</pre>
                            </td>
                        </tr>
                        <tr>
                          <td className="api-field-name">order_items</td>
                            <td>
                              <p>List of items</p>
                              <p className="container-code">"order_items": [</p>
                              <p className="second-l-bracker">{'{'}</p>
                              <p className="list-order">"id": 15,</p>
                              <p className="list-order">"order_id": 5,</p>
                              <p className="list-order">"product_variant_id": 1,</p>
                              <p className="list-order">"quantity": 15,</p>
                              <p className="list-order">"price": "0.02",</p>
                              <p className="list-order">"discount": "0.00",</p>
                              <p className="list-order">"fulfillment_status": "Pending",</p>
                              <p className="list-order">"created_at": "2019-03-26 12:38:29",</p>
                              <p className="second-l-bracker">}</p>
                              <p className="bottom-bracket">]</p>
                              <ul>
                                  <li><b>id:</b> ID of the order line item</li>
                                  <li><b>order_id:</b> ID of the order</li>
                                  <li><b>product_variant_id:</b> ID of the product variant</li>
                                  <li><b>quantity:</b> Quantity of the product variant that is being ordered</li>
                                  <li><b>price:</b> Price of the product variant</li>
                                  <li><b>discount:</b> Discount for the product variant</li>
                                  <li><b>fulfillment_status:</b> Status of the order line item</li>
                                  <li><b>created_at:</b> Date and time that the order line item was created</li>
                                  <li><b>updated_at:</b> Date and time that the order line item was last updated</li>
                              </ul>
                          </td>
                        </tr>
                    </tbody>
                </table>
                <hr />
                <h4 className="header-title">{intl.formatMessage({ id: "order-api-sample-retrieving-all-title" })}</h4>
                <h4 className="header-title">{intl.formatMessage({ id: "order-api-sample-retrieving-all-header" })}</h4>
                <ul>
                  <li>Authorization: Bearer {'{Your-API-Token}'}</li>
                  <li>Accept: application/json</li>
                </ul>
                <small className="get-api">GET /api/order</small>
                <hr />
                <h4 className="header-title">{intl.formatMessage({ id: "order-api-retrieving-specific-order-title" })}</h4>
                <h4 className="header-title">{intl.formatMessage({ id: "order-api-retrieving-specific-order-header" })}</h4>
                <ul>
                  <li>Authorization: Bearer {'{Your-API-Token}'}</li>
                  <li>Accept: application/json</li>
                </ul>
                <small className="get-api">GET /api/order/{'{order}'}</small>
                <hr />
                <h4 className="header-title">{intl.formatMessage({ id: "order-api-retrieving-specific-product-title" })}</h4>
                <h4 className="header-title">{intl.formatMessage({ id: "order-api-retrieving-specific-product-header" })}</h4>
                <ul>
                  <li>Authorization: Bearer {'{Your-API-Token}'}</li>
                  <li>Accept: application/json</li>
                </ul>
                <small className="get-api">POST /api/order</small>
                <pre className="container-code">
                &emsp;{'{'}<br />
                &emsp;    "name": "John Doe",<br />
                &emsp;    "email": "johndoe@email.com",<br />
                &emsp;    "phone": "(999) 999-9999",<br />
                &emsp;    "address_line_1": "1F 111, Block 10",<br />
                &emsp;    "address_line_2": "LongJun Industrial Zone, Dalang",<br />
                &emsp;    "zip": "511700",<br />
                &emsp;    "city": "Shenzhen",<br />
                &emsp;    "province": "Guangdong",<br />
                &emsp;    "country": "China",<br />
                &emsp;    "remarks": "---",<br />
                &emsp;    "order_date": "",<br />
                &emsp;    "processed_date": "01/01/2020",<br />
                &emsp;    "closed_date": "01/02/2020",<br />
                &emsp;    "cancelled_date": "01/03/2020",<br />
                &emsp;    "cancel_reason": "---",<br />
                &emsp;    "subtotal_price": "500",<br />
                &emsp;    "shipping_cost": "100",<br />
                &emsp;    "total_tax": "50",<br />
                &emsp;    "total_discounts": "0",<br />
                &emsp;    "total_price": "650",<br />
                &emsp;    "order_items": [<br />
                &emsp;      {'{'}<br />
                &emsp;        "id": 1, "quantity": 10<br />
                &emsp;      },<br />
                &emsp;      {'{'}
                &emsp;        "id": 2, "quantity": 5<br />
                &emsp;      },<br />
                &emsp;      {'{'}<br />
                &emsp;        "id": 3, "quantity": 15<br />
                &emsp;      }<br />
                &emsp;    ]<br />
                &emsp;}

                </pre>
              </div>
            </div>
          </Wrapper>
        </WidthWrapper>
      </RelativeWrapperWithImage>
    </Layout>
  );
};

export default OrdersAPI;
